<template>
  <div class="section">
    <div class="columns is-centered" :class="{ 'px-0 pt-0': isMobile, 'px-6 py-5': isDesktop }">
      <div class="content column is-12">
        <section v-if="isDesktop">
          <b-message
            v-for="(collapse, index) of collapses"
            :key="index"
            :title="collapse.title"
            :closable="false"
            size="is-medium"
          >
            <span v-html="collapse.text"></span>
          </b-message>
        </section>
        <section v-else>
          <b-collapse
            class="card mb-2"
            animation="slide"
            v-for="(collapse, index) of collapses"
            :key="index"
            :open="isOpen == index"
            @open="isOpen = index"
          >
            <div
              slot="trigger"
              slot-scope="props"
              class="card-header"
              role="button"
            >
              <p class="card-header-title">
                <span v-html="collapse.title"></span>
              </p>
              <a class="card-header-icon">
                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
              </a>
            </div>
            <div class="card-content">
              <div class="content">
                <span v-html="collapse.text"></span>
              </div>
            </div>
          </b-collapse>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import VueBreakpointMixin from 'vue-breakpoint-mixin'

export default {
  name: 'FAQ',
  mixins: [VueBreakpointMixin],
  data() {
    return {
      isOpen: -1,
      collapses: [
        {
          title: 'Кои сте вие и защо да се доверим на вас?',
          text:
            'Блокчейн Тех ЕООД (ЕИК 204944198) е вписано в търговския регистър на България през 2018г. и оперира под бранда CryptoDesk. \nНие сме първото вписано в електронния регистър дружество занимаващо се с подобни услуги в България под номер BB-1 и едно от първите дружества с покритие на битомати в по-големите градове в страната.',
        },
        {
          title: 'Какви са таксите ви?',
          text: 'Ние взимаме 2.5% такса от всяка завършена сделка.',
        },
        {
          title: 'Как се изчислява таксата?',
          text:
            'Нагледно, ако вие искате да купите биткойн от нас и ни преведете 1025лв., значи ще получите биткойн за равностойността на 1000лв. \nАко искате да ни продадете биткойн и ни изпратите биткойн, който може да се продаде за 1025лв., то по вашата банкова сметка ще постъпят 1000лв. \nНачина, по който достигаме до крайната цена е като вземем котировка на криптовалута спрямо евро и обърнем в лева по фиксинга на БНБ от 1.95583 евро за лев.',
        },
        {
          title:
            'Колко време ще отнеме докато банковия превод стигне до клиента или до CryptoDesk?',
          text:
            'Зависи в колко часа е нареден превода и коя сетълмент система се използва. \nЧасовете за сетълмент на левове на БИСЕРА са 10:00, 13:30 и 16:00 часа., а за РИНГС преводите се изпълняват почти в реално време до 17:30ч., но пак проверeте при вашата банка за по-добра точност. Ако превода идва от ваша микросметка в Epay.bg, то той ще постъпи почти в реално време.',
        },
        {
          title: 'Какво се изисква от мен, за да сключим сделка?',
          text:
            '1. Да се регистрирате с имейл и парола на сайта. \n2. Да преминете процеса по верификация с документ за самоличност. \n3. Ако закупувате криптовалута: да ни предоставите адрес на ваш портфейл, до който вие имате достъп и да извършите банков превод. \n4. Ако продавате криптовалута: да ни предоставите IBAN и да изпратите желаната от вас сума на посочения от нас публичен адрес.',
        },
        {
          title:
            'Къде мога да се информирам повече, за това какво е криптовалута?',
          text:
            'Интернет е пълен с информация - в момента работим в посока това да има по-достъпен обучителен материал за нашите клиенти.',
        },
        {
          title: 'Какви валутни двойки предлагате?',
          text:
            'За сега предлагаме най - популярните криптовалути спрямо лева. С течение на времето ще може да предложим различни фиатни и стабилни валути като котировки към базовите валути.',
        },
        {
          title: 'Обслужвате ли Юридически лица?',
          text:
            'CryptoDesk обслужва ЮЛ, като със самият процес можете да се започнаете повече на нашата <a href="https://help.cryptodesk.bg/obsluzhvanie-na-iuridichieski-litsa/" target=_blank>Помощна страница.</a>',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.content {
  white-space: pre-wrap;
  text-align: justify;
  text-justify: inter-word;

  .message {
    box-shadow: 5px 10px 18px #888888;
    margin-bottom: 2em;
    white-space: pre-wrap;
  }

  .message.is-medium {
    font-size: 1rem !important;
  }
}
</style>
